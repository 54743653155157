<template>
  <div class="text-center text-danger">
    <h3>Erro 404 :(</h3>
    <h5>Você não tem permissão para acessar esta página</h5>
    <div class="mt-5 pt-5 text-secondary">
      <router-link
        :to="
          $store.state.menu.filter((x) =>
            x.permissao.includes($store.state.auth.user.tipo)
          )[0].link
        "
        >Página Inicial</router-link
      >
      <a
        href="/logout"
        class="ml-4"
        >Logout</a
      >
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route);
    setTimeout(() => {
      try {
        console.log(
          "link",
          this.$store.state.menu.filter((x) =>
            x.permissao.includes(this.$store.state.auth.user.tipo)
          )[0]
        );
        let findMenu = this.$store.state.menu
          ? this.$store.state.menu.filter((x) =>
              x.permissao.includes(this.$store.state.auth.user.tipo)
            )[0].link
            ? this.$store.state.menu.filter((x) =>
                x.permissao.includes(this.$store.state.auth.user.tipo)
              )[0].link
            : null
          : null;

        window.location.href = findMenu || "/logout";
      } catch (e) {
        console.log(e);
        window.location.href = "/logout";
      }
    }, 2000);
  },
};
</script>

<style></style>
